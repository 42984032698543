
import { computed, defineComponent, ref } from 'vue';
import QrCode from 'qrcode';
import pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

import router, { RouteRecord } from '@/router';
import { user } from '@/composable/auth';
import { FileType, fileTypes } from '@/view-models/promote.vm';
import { loadImage } from '@/composable/file';
import { UserVM } from '@/view-models/auth.vm';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

export default defineComponent({
  setup() {
    const qrCodeImageUrl = ref('');
    const dmImageUrl = ref('');
    const type = computed(() => router.currentRoute.value.params.type as FileType);
    const logoUrl = computed(() => {
      switch (type.value) {
        case 'consumer':
          return `${process.env.BASE_URL}re-appicon.png`;
        case 'deliveryDriver':
          return `${process.env.BASE_URL}delivery.png`;
        case 'boss':
          return `${process.env.BASE_URL}reboss-appicon.png`;
      }
    });
    const dmUrl = computed(() => {
      switch (type.value) {
        case 'consumer':
          return `${process.env.BASE_URL}consumer-dm.png`;
        case 'deliveryDriver':
          return `${process.env.BASE_URL}delivery-dm.png`;
        case 'boss':
          return `${process.env.BASE_URL}boss-dm.png`;
      }
    });
    const qrCodeUrl = computed(() => {
      switch (type.value) {
        case 'consumer':
          return `https://i.myre.life/mgm/${(user.value as UserVM)?.promoteCode}`;
        case 'deliveryDriver':
          return `${window.location.origin}?code=${(user.value as UserVM)?.promoteCode}`;
        case 'boss':
          return `https://www.myre.life/tw/events/customer-recommend/official/?name=${user.value?.name}&mobile=${user.value?.callingCode}${user.value?.mobile}`;
      }
    });
    const freeQuota = computed(() => {
      switch (type.value) {
        case 'consumer':
          return 200;
        case 'deliveryDriver':
          return 30;
        case 'boss':
          return 30;
      }
    });

    async function generateDM() {
      const canvas = document.createElement('canvas');
      let width = 0;
      let height = 0;
      switch (type.value) {
        case 'consumer':
        case 'deliveryDriver':
          width = 1622;
          height = 1300;
          break;
        case 'boss':
          width = 1188;
          height = 1686;
          break;
      }
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const dm = await loadImage(dmUrl.value);
      ctx.imageSmoothingEnabled = false;
      ctx.fillRect(0, 0, width, height);
      ctx.fillStyle = '#ffffff';
      ctx.drawImage(dm, 0, 0, width, height);

      switch (type.value) {
        case 'consumer': {
          const qrCode = await loadImage(qrCodeImageUrl.value);
          ctx.drawImage(qrCode, 295, 417, 264, 264);
          break;
        }
        case 'deliveryDriver': {
          const qrCode = await loadImage(qrCodeImageUrl.value);
          ctx.drawImage(qrCode, 286, 439, 264, 264);

          ctx.font = `55px bold`;
          ctx.textAlign = 'center';
          ctx.fillStyle = '#000000';
          ctx.fillText((user.value as UserVM)?.promoteCode ?? '', 1235, 725);
          break;
        }
        case 'boss':
          const qrCode = await loadImage(qrCodeImageUrl.value);
          ctx.drawImage(qrCode, 714, 1507, 144, 144);
          break;
      }

      dmImageUrl.value = canvas.toDataURL();
    }

    async function generateQRCode() {
      const canvas = document.createElement('canvas');

      await QrCode.toCanvas(canvas, qrCodeUrl.value, {
        width: 200,
        margin: 1.5,
        errorCorrectionLevel: 'Q',
        // version: 10,
      });

      await addLogo(canvas);

      qrCodeImageUrl.value = canvas.toDataURL();
    }

    async function addLogo(canvas: HTMLCanvasElement): Promise<void> {
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const logo = await loadImage(logoUrl.value);
      ctx.imageSmoothingEnabled = false;
      ctx.fillStyle = '#FFF';
      ctx.fillRect(77, 77, 46, 46);
      ctx.drawImage(logo, 80, 80, 40, 40);
    }

    function onDownloadQrCode() {
      pdfMake
        .createPdf({
          pageSize: 'A4',
          pageMargins: [30, 15, 30, 15],
          content: Array(12)
            .fill('')
            .map((_) => {
              return {
                columns: Array(8)
                  .fill('')
                  .map((_, i) => {
                    return {
                      image: qrCodeImageUrl.value,
                      width: 58,
                    };
                  }),
                columnGap: 10,
                margin: [0, 5, 0, 5],
              };
            }),
        })
        .open();
    }

    function onDownloadDm() {
      switch (type.value) {
        case 'consumer':
        case 'deliveryDriver':
          pdfMake
            .createPdf({
              pageSize: 'A4',
              pageMargins: 30,
              content: [
                {
                  image: dmImageUrl.value,
                  width: 358.60507335190334,
                  alignment: 'center',
                },
              ],
            })
            .open();
          break;
        case 'boss':
          pdfMake
            .createPdf({
              pageSize: 'A4',
              pageMargins: 0,
              content: [
                {
                  image: dmImageUrl.value,
                  width: 595.28,
                },
              ],
            })
            .open();
      }
    }

    (async function() {
      await generateQRCode();
      generateDM();
    })();

    return {
      qrCodeUrl,
      qrCodeImageUrl,
      dmImageUrl,
      freeQuota,

      onDownloadQrCode,
      onDownloadDm,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (!fileTypes.includes(to.params?.type as FileType)) {
      return next({ name: RouteRecord.DownloadDetails.name, params: { type: fileTypes[0] } });
    }

    next();
  },
});
